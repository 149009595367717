import React, { useContext, useEffect, useRef, useState } from "react";

import { useUserStore } from "@speedwaymotors/clutch/Stores/UserStore/UserStore";
import { IsUserBot } from "@speedwaymotors/clutch/Utilities/CookieUtilties/UserCookieUtilities";
import AutoComplete from "Clutch/Atoms/Form/Elements/AutoComplete/AutoComplete";
import Typography from "Clutch/Atoms/Typography";
import useSegment from "Clutch/Hooks/useSegment/useSegment";
import { useThemeStore } from "Clutch/Stores/ThemeStore";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import { NavigationContext } from "Contexts/Navigation/NavigationContext";
import { FetchRaceTypes } from "Contexts/VehicleContext/CoreVehicleRequests";
import Button from "Molecules/Button";
import ClassNames from "classnames";

import { useFitmentStateContext } from "../../../../../providers/FitmentStateProvider";
import { AddNewRaceTypeToGarage } from "../../../../../utils/vehicleUtils";
import styles from "../YmmSelector.module.scss";

const RaceTypeTab = ({ isEnabled }) => {
  const updateRaceTypes = useVehicleStore((x) => x.updateRaceTypes);
  const raceTypes = useVehicleStore((x) => x.context.raceTypes);
  const selectedFacetData = useVehicleStore((x) => x.context.selectedFacetData);
  const selectedCustomerProject = useVehicleStore((x) => x.context.selectedCustomerProject);
  const setIsLoading = useThemeStore((state) => state.setIsLoading);
  const { sendCustomSegmentTrackEvent } = useSegment();
  const [errorState, setErrorState] = useState(false);
  const { state: navigationState } = useContext(NavigationContext);
  const { garage } = navigationState;
  const { isYmmModalOpen } = garage;
  const { isFindPartsClicked, setIsFindPartsClicked } =
    useFitmentStateContext();

  const options = [];
  const raceRef = useRef();

  raceTypes?.forEach((raceType) => {
    options.push({
      label: raceType?.displayName,
      value: raceType?.raceTypeId,
    });
  });

  useEffect(() => {
    if (isFindPartsClicked && isEnabled) {
      setIsFindPartsClicked(false);
      handleButtonClick();
    }
  }, [isFindPartsClicked, isEnabled]);

  useEffect(() => {
    async function fetchAllRaceTypes() {
      var response = await FetchRaceTypes(
        raceTypes,
        "/graphql/trait",
        selectedFacetData
      );
      if (response) {
        response.raceTypeLinks?.sort((raceType1, raceType2) => {
          if (raceType1.priority > raceType2.priority) return 1;
          if (raceType2.priority > raceType1.priority) return -1;
          return 0;
        });
        updateRaceTypes(response, selectedCustomerProject?.raceTypeId);
      }
    }
    if (!IsUserBot()) fetchAllRaceTypes();
  }, [isEnabled, raceTypes]);

  useEffect(() => {
    if (selectedCustomerProject?.raceTypeId && errorState) {
      setErrorState(false);
    }
  }, [errorState, selectedCustomerProject]);

  const handleButtonClick = () => {
    if (
      selectedCustomerProject?.raceTypeId === null &&
      raceRef?.current &&
      !isYmmModalOpen
    ) {
      raceRef.current.focus();
      setErrorState(true);
    }
  };

  const userGuid = useUserStore((x) => x.context.userGuid);
  const updateUserGuid = useUserStore((x) => x.context.updateUserGuid);

  return (
    <div className={styles.modalPanel}>
      <div className={ClassNames(styles.raceTab, styles.ymmContainer)}>
        <div
          className={`${ClassNames(
            styles.ymmModalItem,
            styles.raceTypes
          )} lr-RaceSelector`}
        >
          <AutoComplete
            options={options}
            onChange={async (event) => {
              if (!IsUserBot()) {
                let raceTypeId = parseInt(event.value, 10);
                AddNewRaceTypeToGarage(
                  raceTypeId,
                  userGuid,
                  raceTypes,
                  "/graphql/customerproject",
                  updateUserGuid,
                  updateRaceTypes,
                  setIsLoading,
                  selectedFacetData,
                  sendCustomSegmentTrackEvent
                );
              }
            }}
            label={"Select Your Race Car"}
            selectedValue={
              selectedCustomerProject?.raceTypeId != null
                ? {
                    value: selectedCustomerProject?.raceTypeId,
                    label: selectedCustomerProject?.raceDisplay,
                  }
                : null
            }
            placeholder={"Race Car"}
            isErrorState={errorState}
            ref={raceRef}
            useNativeSelect={false}
          />
        </div>
        <Button
          className={styles.addRaceVehicleButton}
          brand={"secondary"}
          fill
          size={"small"}
          type={"button"}
          onClick={handleButtonClick}
          segmentEvent={createClickedSegmentEvent("AddRaceVehicleHomePage")}
        >
          <Typography
            className={styles.buttonText}
            tone={"contrast"}
            size={0.75}
            font={"bold"}
            inline
            data-testid={"findPartsRace"}
          >
            Find Parts
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default RaceTypeTab;
